import React from 'react'
import PropTypes from 'prop-types'

import style from '../styles/post.module.css'

const Intro = ({ text }) => (
  <div className={style.intro}>
    <p>{text}</p>
  </div>
)

Intro.propTypes = {
  text: PropTypes.string,
}

export default Intro
