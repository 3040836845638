import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import PostLink from '../components/post.link'
import Intro from '../components/intro'
import Navigation from '../components/navigation'

const Journal = ({ data, pageContext: { nextPagePath, previousPagePath } }) => {
  const {
    allMarkdownRemark: { edges: posts },
  } = data
  return (
    <>
      <SEO />
      <Layout>
        <Intro text="A collection of thoughts, project updates and interesting links." />
        {posts.map(({ node }) => {
          const { 
            frontmatter: meta, 
          } = node
          return <PostLink key={node.id} meta={meta} excerpt={node.excerpt} readingTime={node.fields.readingTime.text} />
        })}

        <Navigation
          previousPath={previousPagePath}
          previousLabel="Newer posts"
          nextPath={nextPagePath}
          nextLabel="Older posts"
        />
      </Layout>
    </>
  )
}

Journal.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    nextPagePath: PropTypes.string,
    previousPagePath: PropTypes.string,
  }),
}

// query($limit: Int!, $skip: Int!) {
// query JournalQuery {
export const journalQuery = graphql`
  query($limit: Int!, $skip: Int!) {    
    allMarkdownRemark(
      filter: {frontmatter: {published: {eq: true}}, fileAbsolutePath: {regex: "//content/journal//"}}
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            type
            date
            path
            link
            published
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`

export default Journal
